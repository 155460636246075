import * as R from 'ramda'
import React from 'react'
import { graphql } from 'gatsby'
import { Button } from '@material-ui/core'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import BlockContent from '../components/BlockContent'
import { mapIndexed, notNilOrEmpty } from '../lib/Helpers'
import Hero from '../components/Hero'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

export default props => {
  const { data } = props
  const { sanityProgrammingPage: content } = data

  return (
    <Layout>
      <SEO
        title={`${content.pageTitle} | Afwerx Engage Space Virtual Showcase`}
        keywords={content.seo.keywords}
        description={content.seo.description}
        author={content.seo.author}
        image={content.seo.seo_image.asset.url}
        url={`https://engage.space/${content.slug.current}`}
      />
      <Hero
        title={content.pageTitle}
        className="modules-page"
        img={require('../assets/imgs/space_banners_header.jpg')}
      />
      <div className="page--modules">
        <section className="container intro-text page--modules">
          <BlockContent blocks={content._rawIntro} />
        </section>
        <section className="section--modules">
          {mapIndexed((item, index) => {
            return (
              <div
                id={item.section_id}
                className="section--modules__single"
                key={index}
              >
                <div
                  className="section--modules__single__separator"
                  style={{
                    backgroundImage: `url(${item.section_background.asset.url})`
                  }}
                >
                  <div className="container section--modules__single__separator__content">
                    <img
                      src={item.icon.asset.url}
                      alt={item.icon.asset.originalFilename}
                      className="section--modules__single__separator__content__img"
                    />
                    <div className="section--modules__single__separator__content__text">
                      <h1 className="highlight color--yellow">
                        {item.separator_highlight}
                      </h1>
                      <h2 className="title color--white">
                        {item.separator_title}
                      </h2>
                      <p className="subtitle color--white">
                        {item.separator_subtitle}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="container module-content">
                  <div className="module-content__text">
                    <BlockContent blocks={item._rawSectionContent} />
                  </div>
                  <AniLink
                    className="btn btn--black"
                    cover
                    to="/agenda"
                    duration={1}
                    direction="right"
                    bg={`
                      center / 50%     /* position / size */
                      no-repeat        /* repeat */
                      fixed            /* attachment */
                      padding-box      /* origin */
                      content-box      /* clip */
                      #00021a          /* color */
                    `}
                  >
                    RETURN TO AGENDA
                  </AniLink>
                </div>
              </div>
            )
          })(content.sections)}
        </section>
      </div>
    </Layout>
  )
}

export const agendaQuery = graphql`
  query {
    sanityProgrammingPage {
      _rawIntro
      pageTitle
      slug {
        current
      }
      seo {
        author
        description
        keywords
        seo_image {
          asset {
            url
          }
        }
      }
      sections {
        _rawSectionContent
        icon {
          asset {
            originalFilename
            url
          }
        }
        section_background {
          asset {
            url
          }
        }
        separator_highlight
        separator_title
        separator_subtitle
        section_id
      }
    }
  }
`
